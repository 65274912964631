import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout slug="/404">
    <div className="container has-text-centered mt-6 mb-6">
      <h1>Página não encontrada</h1>
      <p>Volte ao início <Link to="/">clicando aqui</Link></p>
    </div>
  </Layout>
);

export default NotFoundPage;
